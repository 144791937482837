import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const AllChatConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/allchat/:id?",
    component: React.lazy(() => import("./allchat")),
  },
  {
    path: "/allchat",
    component: () => {
      return <Redirect to="/allchat" />;
    },
  },
];
